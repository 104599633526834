import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueI18n from 'vue-i18n'
import messages from '../locale/index.js'
import 'lib-flexible'
import { Toast } from 'vant';
import 'view-design/dist/styles/iview.css';
import { setStroe, getStore } from '@/util/util.js'
import { onChangeWidth } from '@/util/onChangeWidth.js'
import axios from 'axios'

import { Button, DropdownItem, Dropdown, Icon, DropdownMenu , Modal} from 'view-design';
Vue.component('Button', Button);
Vue.component('Dropdown', Dropdown);
Vue.component('DropdownItem', DropdownItem);
Vue.component('DropdownMenu', DropdownMenu);
Vue.component('Icon', Icon);
Vue.component('Modal', Modal);


Vue.use(Toast);
Vue.use(VueI18n)
let localLang = getStore({ name: 'lang' })?.value
let langStr = navigator.language.toLowerCase()
let lang = langStr.includes('zh') ? 'zh-Hant' : langStr.includes('ja') ? "ja-jp" : langStr.includes('pt') ? "pt-br" : langStr.includes('es') ? "es" : 'en'
var locale = localLang || lang
const langList = [
  {
    name: "简体中文",
    value: "zh-Hans",
  },
  {
    name: "繁體中文",
    value: "zh-Hant",
  },
  {
    name: "English",
    value: "en",
  },
  {
    name: "日本語",
    value: "ja-jp",
  },
  {
    name: "Português",
    value: "pt-br",
  },
  {
    name: "Español",
    value: "es",
  }
]
let currentLang = langList.find((item) => item.value === locale)
setStroe({
  name: 'lang',
  content: currentLang
})
store.state.lang.lang = currentLang
const i18n = new VueI18n({
  locale,
  messages
})
// 获取配置
axios.get('https://member.betpay.cc/info/website-setting')
  .then(function (response) {
    store.commit('setConfig',
      response.data.data || null
    )
  })
  .catch(function (error) {
    // 处理错误情况
    console.log(error);
  })
  .then(function () {
    // 总是会执行
  });

Vue.config.productionTip = false

const app = new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
onChangeWidth(app)
