export default {
  "Headers": {
    "tab1": "ホーム",
    "tab2": "チュートリアル",
    "tab3": "ビジネスパートナーシップ",
    tab4: "マスコミ",
    "btntext": "ダウンロード",
    "tabT1": "PayBet-ホーム",
    "tabT2": "PayBet-チュートリアル",
    "tabT3": "PayBet-ビジネスパートナーシップ",
    tabT4: "PayBet-マスコミ",
    temText1: "我が社のメディアに参加する"
  },
  "home": {
    "title": "ホーム",
    "dText1": "Appleでダウンロード",
    "dText2": "Androidでダウンロード",
    "dText3": "Webでアクセス",
    "text1": "高速で価値ある、ゲームを楽しむ",
    "text2_1": "スーパーファストチャージ",
    "text2_2": "究極のエクスペリエンス",
    "text3": "時間や国境に制約されず、安全かつ効率的な取引がいつでもどこでも可能で、追跡不可能で、",
    "text3_1": "時間や国境に制約されず、安全かつ効率的な取引がいつでもどこでも可能で、追跡不可能で、凍結できない",
    "text4": "凍結できない",
    "text5": "友情のお知らせ：PayBetのユーザーは支払いを受け取っていない場合、送金しないでください。操作ミスがあれば、損失は自己負担となります。",
    "smText1": "プライバシーコイン",
    "smText2": "法定通貨の使用を免除し、高セキュリティな取引方法を提供します。",
    "smText3": "総資産",
    "smText4": "クイックバイ",
    "smText5": "注文を出品",
    "smText6": "私の注文",
    "smText7": "全体を販売",
    "smText8": "今すぐ購入",
    "wdText1": "安定通貨",
    "wdText2": "高い安定性の為替レート、世界中の法定通貨の価値が一定",
    "wdText3": "ハイライト機能",
    "wdText4": "受け取り",
    "wdText5": "支払い",
    "wdText6": "転送",
    "jdText1": "操作が簡単",
    "jdText2": "注文、支払い、送金、取引は3つのステップで完了",
    "jdText3": "取引が成功しました",
    "jdText4": "あなたは成功裏に販売しました",
    "jdText5": "現在の利用可能残高",
    "jdText6": "取引番号",
    "jdText7": "成約",
    "kjText1": "クロスボーダートレード",
    "kjText2": "24/7トレード、国境なしで、瞬時に価値が加わります",
    "kjText3": "販売者の受け取り情報",
    "kjText4": "受取人の名前",
    "kjText5": "受け取り方法",
    "kjText6": "受け取り口座",
    "kjText7": "Paypal",
    "kjText8": "購入者の支払い情報",
    "kjText9": "支払い人の名前",
    "kjText10": "支払い方法",
    "kjText11": "支払い口座",
    "kjText12": "伊藤**ずえ",
    "kjText13": "京マ**チ子",
    "yyText1": "全面的な応用",
    "yyText2": "究極の体験",
    "yyText3": "銀行口座番号",
    "qqText1": "グローバルなサーバー展開",
    "qqText2": "時間や国境に制約されず、いつでもどこでもトレードが完了し、瞬時に価値が加わります"
  },
  "footer": {
    "text1": "PayBetをダウンロードして新しい旅を始めましょう",
    "text2": "時間や国境に制約されず、いつでもどこでも安全で効率的な取引が可能で、追跡不可能で、凍結できません",
    "text3": "苦情のメール",
    "text4": "お問い合わせメール"
  },
  "cooperate": {
    "topText1": "私たちのサービス",
    "topText2": "商人向けに包括的なペイメントサービスを提供し、商人のオンライン一体化した支払いニーズを満たします。支払い分野での多年にわたる技術とサービスの経験を活かし、顧客に包括的なアカウントシステムを構築し、代払い、代収、アカウント管理などのアカウント管理サービスを提供します。",
    "topText3": "Telegramカスタマーサービス",
    "topText4": "PayBet公式チャンネル",
    "topText5": "チャンネルに入れる言語を選びます",
    "cItemText1": "資金の安全性",
    "cItemText2": "凍結できず、複数のリスク管理、厳格な審査により、資金は100％安全です",
    "cItemText3": "スマート認識",
    "cItemText4": "ユーザー行動",
    "cItemText5": "身元確認",
    "tcItemText1": "クリアなアカウント",
    "tcItemText2": "アカウントは完全で、明確で、細かい取引の詳細を把握します",
    "tcItemText3": "秒で入金",
    "tcItemText4": "収支明細",
    "tcItemText5": "資金明細"
  },
  "course": {
    "title1": "実名認証",
    "title2": "受け取りと支払い",
    "title3": "コインの販売チュートリアル",
    "title4": "コインの購入チュートリアル",
    "title5": "バリューの追加チュートリアル",
    "title6": "コインの引き出しチュートリアル"
  },
  iosModal: {
    "text1": "デスクトップでアプリのダウンロードが完了したら、以下の手順に従って信頼を設定し、正常に使用できるようにしてください:",
    "text2": "ホーム画面に戻り、iOSシステム設定を開く",
    "text3": "開く",
    "text4": "設定 - 一般 - VPNとデバイス管理",
    "text5": "インストールしたばかりのアプリを見つけて、信頼を設定をクリックする"
  }
}