
import { setStroe } from '@/util/util.js'
const side = {
  state: {
    config: {}
  },
  mutations: {
    setConfig(state, config) {
      console.log(config,'config');
      state.config = config
      setStroe({
        name: "config",
        content: config,
      })
    }
  },
  actions: {
  },
}
export default side