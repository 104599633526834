export default {
  Headers: {
    tab1: 'Home',
    tab2: 'Tutorial',
    tab3: "Cooperation",
    tab4: "Social media",
    btntext: 'Scan Code',
    tabT1: 'PayBet-Home',
    tabT2: 'PayBet-Tutorial',
    tabT3: "PayBet-Cooperation",
    tabT4: "PayBet-Social media",
    temText1: "Join us on social media"
  },
  home: {
    "title": "Home",
    "dText1": "Download on Apple",
    "dText2": "Download on Android",
    "dText3": "Web Access",
    "text1": "Download PayBet to embark on a new journey",
    "text2_1": "Super Fast Charging",
    "text2_2": "Ultimate Experience",
    "text3": "No time and geographical restrictions, transactions can be completed safely and efficiently anytime, anywhere, untraceable,",
    "text3_1": "No time and geographical restrictions, transactions can be completed safely and efficiently anytime, anywhere, untraceable, cannot be frozen",
    "text4": "Cannot be frozen",
    "text5": "Friendly Reminder: Dear PayBet user, if you have not received the payment, please do not make any transactions. In case of operation mistakes, you will bear the loss yourself",
    "smText1": "Private Currency",
    "smText2": "Providing you with a highly secure way to trade without using fiat currency",
    "smText3": "Total Assets",
    "smText4": "Quick Buy",
    "smText5": "Listings",
    "smText6": "My Listings",
    "smText7": "Sell Entire Listing",
    "smText8": "Buy Now",
    "wdText1": "Stable Currency",
    "wdText2": "High exchange rate stability, constant global fiat currency value",
    "wdText3": "Hot Features",
    "wdText4": "Collect",
    "wdText5": "Pay",
    "wdText6": "Transfer",
    "jdText1": "Easy Operations",
    "jdText2": "Place an order, make payment, transfer funds – transactions can be completed in just three steps",
    "jdText3": "Transaction Successful",
    "jdText4": "You have successfully",
    "jdText5": "Current Available Balance",
    "jdText6": "Transaction ID",
    "jdText7": "Completed",
    "kjText1": "Cross-Border Transactions",
    "kjText2": "24/7 trading without borders, top-up completed in one second",
    "kjText3": "Seller's Payment Info",
    "kjText4": "Payee's Name",
    "kjText5": "Payment Method",
    "kjText6": "Payment Account",
    "kjText7": "Alipay",
    "kjText8": "Buyer's Payment Information",
    "kjText9": "Payer's Name",
    "kjText10": "Payment Method",
    "kjText11": "Payment Account",
    "kjText12": "Wang * Er",
    "kjText13": "Lee * Bo",
    "yyText1": "Widely applied",
    "yyText2": "Good service experience",
    "yyText3": "Bank Card No",
    "qqText1": "Worldwide Server Deployment",
    "qqText2": "Transactions can be completed regardless of time and geographical restrictions, top-up completed in one second"
  },
  footer: {
    "text1": "Download PayBet to start a new journey",
    "text2": "No time and geographical restrictions, transactions can be completed safely and efficiently anytime, anywhere, untraceable, cannot be frozen",
    "text3": "Complaint Email",
    "text4": "Query Email"
  },
  "cooperate": {
    "topText1": "Our Services",
    "topText2": "We provide comprehensive aggregated payment services for merchants, meeting their online integrated payment needs. Leveraging years of technical and service experience in the payment field, we build a comprehensive account system for clients, offering account management services such as proxy payment, proxy collection, and account management.",
    "topText3": "Telegram Customer Service",
    "topText4": "PayBet official channel",
    "topText5": "Select the language to join the channel",
    "cItemText1": "Fund Security",
    "cItemText2": "Cannot be frozen, multiple risk controls, strict approval process, ensuring 100% fund security",
    "cItemText3": "Auto Identify",
    "cItemText4": "User Behavior",
    "cItemText5": "Identification",
    "tcItemText1": "Clear Accounts",
    "tcItemText2": "Complete, clear, and transparent account details, fully understanding the flow of funds",
    "tcItemText3": "Quick Receipt",
    "tcItemText4": "Funding Details",
    "tcItemText5": "Fund Bill"
  },
  course: {
    title1: "Real name authentication",
    title2: "Collection and payment",
    title3: "Selling Coins Tutorial",
    title4: "Buying Coins Tutorial",
    title5: "Value Added Tutorial",
    title6: "Currency Withdrawal Tutorial"
  },
  iosModal:{
    "text1": "After the app is downloaded on the desktop, you must follow these steps to set it as trusted for it to function properly:",
    "text2": "Return to the home screen and open iOS system settings",
    "text3": "Open",
    "text4": "Settings - General - VPN & Device Management",
    "text5": "Find the newly installed app and click Set Trust"
  }
  
}