
import { setStroe, getStore } from '@/util/util.js'
const state = {
  state: {
    cWidth: getStore({
      name: 'cWidth'
    })
  },
  mutations: {
    setCWidth(state, cWidth) {
      state.cWidth = cWidth
      setStroe({
        name: "cWidth",
        content: cWidth,
      })
    }
  },
}
export default state