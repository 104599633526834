import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/home/home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/cooperate',
    name: 'cooperate',
    component: () => import('@/views/cooperate/cooperate.vue')
  },
  {
    path: '/course',
    name: 'course',
    component: () => import('@/views/course/course.vue')
  },
  {
    path: '/socialmedia',
    name: 'socialmedia',
    component: () => import('@/views/socialmedia/index.vue')
  },
  

]

const router = new VueRouter({
  routes
})

export default router
