import EN from './en'
import Hans from './zh-Hans'
import Hant from './zh-Hant'
import ES from './es'
import JA from './ja-jp'
import PT from './pt-br'


export default {
  'en': EN,
  'zh-Hans': Hans,
  'zh-Hant': Hant,
  'es': ES,
  'ja-jp': JA,
  'pt-br': PT
}
