import store from '@/store/index.js'

export const onChangeWidth = function(vm) {
  // 首先执行一次
  let width = document.documentElement.clientWidth;
  store.commit('setCWidth', width)
  window.onresize = function () {
    // 随着窗口变化而改动
    width = document.documentElement.clientWidth;
    store.commit('setCWidth', width)
    console.log(vm.$route.path ,'当前路由');
  };
}