
import { setStroe, getStore } from '@/util/util.js'
const side = {
  state: {
    lang: getStore({
      name: 'lang'
    }),
  },
  mutations: {
    setLang(state, lang) {
      state.lang = lang
      setStroe({
        name: "lang",
        content: lang,
      })
    }
  },
  actions: {
  },
}
export default side