import Vue from 'vue'
import Vuex from 'vuex'
import getters from './getters'
import lang from './modules/lang'
import client from './modules/client'
import config from './modules/config'

Vue.use(Vuex)
const store = new Vuex.Store({
  modules: {
    lang,
    client,
    config
  },
  getters
})
export default store
